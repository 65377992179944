html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.text-center {
    text-align: center;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    padding: 0 20px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 72px;
}

h1 .thin {
    font-weight: 300;
}

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 62px;
    margin-bottom: 25px;
}

h3 {
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
}

h2, h3 {
    color: #003363;
}

p {
    font-size: 16px;
}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }

.site-header p {
    line-height: 32px;
    color: #D8ECFF;
}

.main p {
    line-height: 24px;
    margin-bottom: 28px;
    color: #003363;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

.site-logo {
    font-size: 0px;
    background: url(../image/logo.svg);
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    max-width: 100%;
    height: 35px;
}

.logo-wrapper {
    padding-top: 25px;
    padding-bottom: 22px;
}

.site-header {
    color: #fff;
}

.site-header .container {
    position: relative;
}

.site-header .container.hero:after {
    content: ' ';
    position: absolute;
    display: block;
    width: 305px;
    height: 468px;
    background-image: url('../image/hands.png');
    background-repeat: no-repeat;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.site-header-inner {
    background-color: #111D4F;
    border-radius: 29px;
    position: relative;
    padding: 45px 60px 75px;
}

.header-image {
    position: absolute;
    right: 0;
    bottom: 0;

    /* TODO */
}

.header-description {
    margin-bottom: 60px;
}

.nav-wrapper p {
    margin-bottom: 54px;
    letter-spacing: 0.345em;
}

.nav-wrapper ul {
    display: flex;
}

.nav-wrapper li {
    box-shadow: 0px 4px 33px 6px rgba(0, 0, 0, 0.1);
    height: 65px;
    border-radius: 50px;
    margin-right: 31px;
}

.nav-wrapper a {
    box-shadow: inset 0 0 0 0 #47D982;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
    display: block;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    text-indent: 67px;
    line-height: 65px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    margin-bottom: 58px;
    position: relative;
    padding-right: 40px;
    color: #FFF;
    white-space: nowrap;
}

.nav-wrapper a:hover {
    opacity: 1;
    background-color: #47D982;
    color: #FAFAFA;
    transition: all 0.15s linear;
    cursor: pointer;
    box-shadow: inset 0 0 0 50px #47D982;
}

.nav-wrapper a:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    top: 16px;
    left: 20px;
}

.nav-wrapper li:nth-of-type(1) a:before {
    background-image: url('../image/idea.png');
}

.nav-wrapper li:nth-of-type(2) a:before {
    background-image: url('../image/chat.png');
}

.nav-wrapper li:nth-of-type(3) a:before {
    background-image: url('../image/about.png');
}

/*
.nav-wrapper li:hover:nth-of-type(2) a:before {
    background-image: url('../image/about-white.png');
} */

.main section:not(:first-child) {
    margin-top: 42px;
}

#nft-creation-setup {
    margin-top: 54px;
    margin-bottom: 44px;
}

.block {
    background: #F4F6FE;
    border-radius: 29px;
    padding: 22px 60px 52px;
}

.block .box:not(:last-child) {
    border-bottom: 1px solid #CFD4E8;
}

.box .title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 26px;
}

.box .title:before {
    content: '';
    width: 54px;
    height: 54px;
    margin-right: 24px;
    background-position: center;
    background-repeat: no-repeat;
}

.block .box:nth-of-type(1) .title:before {
    background-image: url('../image/brush.png');
}

.block .box:nth-of-type(2) .title:before {
    background-image: url('../image/purse.png');
}

.block .box:nth-of-type(3) .title:before {
    background-image: url('../image/star.png');
}

.block .box:nth-of-type(4) .title:before {
    background-image: url('../image/fire.png');
}


.support {
    grid-gap: 40px;
    display: grid;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
}
.support.col2{
    grid-template-columns: 1fr 1fr;
}
.support .visualization > div {
    max-width: 100%;
    height: 357px;
    background: #F4F6FE;
    border-radius: 29px;
    background-image: url('../image/nft-support.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.team {
    text-align: center;
    background: #111D4F;
    border-radius: 29px;
    padding: 36px 90px 74px;
}

.team h2 {
    color: #FFF;
}

.team p {
    color: #E6E9F8;
}

.team .contact-btn {
    display: inline-block;
    width: 305px;
    height: 65px;
    line-height: 65px;
    background: #47D982;
    border-radius: 50px;
    font-weight: 900;
    font-size: 18px;
    color: #FAFAFA;
    text-decoration: none;
    position: relative;
    transition-duration: .2s;
}
/*
.team .contact-btn:hover::before{
    content: 'Services under development';
    color: #1A464F;;
    font-size: 18px;
    position: absolute;
    width: inherit;
    height: inherit;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    opacity: 0.95;
    box-shadow: 0px 4px 15px rgba(26, 70, 79, 0.3);
    border-radius: 47px;
    transform: translateY(-80px);
}
.team .contact-btn:hover::after{
    content: "";
    clear: both;
    border-top: 8px solid #fff;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    opacity: 0.95;
    position: absolute;
    height: 0;
    left: 46%;
    width: 0;
    transform: translateY(-16px);
}
*/
footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer .copyright {
    color: #828C98;
    font-size: 14px;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}
@media (max-width: 1024px) {
    .site-header .container:after{
        opacity: 0.15;
        top: 48%;
    }
    .support.col2{
        grid-template-columns: 1fr;
    }
    .nav-wrapper ul {
        display: block;
    }
    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }


}
@media only screen and (max-width: 812px) {

    header, 
    article,
    footer {
        padding: 0px 22px;
    }

    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
    }

    h2 {
        font-size: 28px;
        line-height: 36px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }
    
    .m-hidden {
        display: none;
    }

    .header-image {
        display: none;
    }

    .site-header-inner {
        padding: 26px 20px 28px;
    }

    .header-description {
        margin-bottom: 26px;
    }

    .nav-wrapper p {
        margin-bottom: 20px;
    }

    .nav-wrapper li {
        width: 100%;
    }
    .team, .block{
        padding: 30px 15px;
    }
    .team .contact-btn{
        box-shadow: none;
        display: block;
        min-width: auto;
        text-align: center;
        width: 100%;
        white-space: nowrap;
    }
    footer .container {
        flex-direction: column;
        padding-bottom: 24px;
    }

    footer .logo-wrapper {
        padding-bottom: 8px;
    }
}
@media (max-width: 767px) {
    .site-header .container:after{
        top: 43%;
    }
}